.mission {
    display: flex;
}

.mission_title {
    width: 40%;
}

.mission_desc--block {
    width: 60%;
    font-family: 'Gilroy';
    font-size: 20px;
    line-height: 170%;
    padding-left: 40px;
}

.mission_desc:first-child {
    margin-bottom: 10px;
}

@media screen and (max-width: 1400px) {
    .mission_title {
        width: 35%;
    }
    
    .mission_desc--block {
        width: 65%;
    }
}

@media screen and (max-width: 1200px) {
    .mission {
        flex-direction: column;
    }

    .mission_title {
        width: 100%;
    }
    
    .mission_desc--block {
        width: 100%;
    }

    .mission_desc--block {
        padding-left: 0;
    }
}

@media screen and (max-width: 768px) {
    
}