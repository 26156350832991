.segmentation_content {
    display: flex;
    justify-content: space-between;
}

.segmentation {
    padding-bottom: 200px;
}

.segmentation_items {
    display: flex;
    flex-wrap: wrap;
    max-width: 500px;
}

.segmentation_item {
    margin-bottom: 50px;
    width: calc(100% / 2);
}

.segmentation_num {
    font-family: 'e-Ukraine';
    font-weight: 400;
    font-size: 48px;
    line-height: 133%;
    margin-bottom: 3px;
    color: #3D67D5;
}

.segmentation_item:nth-child(2) .segmentation_num {
    color: #FFB2E6;
}

.segmentation_item:nth-child(3) .segmentation_num {
    color: #ABBCE8;
}

.segmentation_item:nth-child(4) .segmentation_num {
    color: #4C577A;
}

.segmentation_item:nth-child(5) .segmentation_num {
    color: #411037;
}

.segmentation_desc {
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: rgba(53, 49, 52, 0.85);
}

@media screen and (max-width: 1200px) {
    .segmentation_diagrama {
        width: 40%;
    }

    .segmentation_items {
        margin-left: 60px;
    }
}

@media screen and (max-width: 992px) {
    .segmentation_content {
        flex-direction: column;
    }

    .segmentation_diagrama {
        width: 343px;
        margin-bottom: 50px;
    }

    .segmentation_items {
        margin-left: 0;
    }

    .segmentation_item:nth-child(1) {
        width: 100%;
    }

    .segmentation_items {
        max-width: unset;
    }

    .segmentation_item:nth-child(4) {
        margin-bottom: 0;
    }

    .segmentation_item:nth-child(5) {
        margin-bottom: 0;
    }
}

@media screen and (max-width: 768px) {
    .segmentation {
        padding-bottom: 130px;
    }

    .segmentation_item:nth-child(2n) {
        width: calc((100% / 2) - 40px);
        margin-right: 40px;
    }

    .segmentation_item:nth-child(1) {
        width: 100%;
        order: 1;
    }

    .segmentation_item:nth-child(2) {
        order: 3;
        margin-right: 0;
        width: 50%;
    }

    .segmentation_item:nth-child(3) {
        order: 2;
        width: fit-content;
        margin-right: 50px;
    }

    .segmentation_item:nth-child(4) {
        order: 5;
        margin-right: 0;
        width: 50%;
    }

    .segmentation_item:nth-child(5) {
        order: 4;
        width: fit-content;
        margin-right: 50px;
    }
}
@media screen and (max-width: 576px) {
    .segmentation_desc {
        font-size: 18px;
    }
}