main::before {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -380px;
    background-image: url('../../img/main/bcg_grad.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100vw;
    height: 100%;
    max-height: 1900px;
    z-index: -3;
}

.main_wrap::before {
    content: '';
    position: absolute;
    left: 50%;
    transform: translate(-45%, -50%);
    top: 63%;
    background-image: url('../../img/main/free.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 188px;
    height: 188px;
    z-index: -3;
}

.main_block {
    display: flex;
    justify-content: space-between;
    padding-top: 35px;
    margin-bottom: 50px;
}

.main_content {
    width: 55%;
}

.main_img_block {
    top: -160px;
    right: -70px;
}

.main_title {
    margin-bottom: 40px;
}

.main_undertitle {
    font-weight: 300;
    margin-bottom: 40px;
    max-width: 430px;
    width: 100%;
    line-height: 1.4;
    font-family: 'Gilroy', sans-serif;
}

.main_btns_wrap {
    display: flex;
    max-width: 426px;
}

.main_btns_wrap button {
    margin-right: 26px;
}

.main_img--mob {
    display: none;
}

@media screen and (max-width: 1400px) {
    .main_content {
        width: 74%;
        margin-right: 20px;
    }

    .main_img_block {
        top: -120px;
        right: 0;
    }

    .main_wrap::before {
        width: 133px;
        height: 133px;
        transform: translate(-35%, -50%);
        top: 71%;
    }
}

@media screen and (max-width: 1400px) and (min-width: 1201px) and (max-height: 800px) {
    .main_block {
        margin: 0 auto 0;
        height: 100vh;
    }

    .main_img_block {
        top: 10%;
    }

    .main_content {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

@media screen and (max-width: 1400px) and (min-width: 1201px) and (max-height: 600px) {
    .main_block {
        margin: 50px auto 0;
        height: 100%;
    }
}

@media screen and (max-width: 1200px) {
    .main_content {
        width: 55%;
        margin-right: 0;
    }

    .main_img_block {
        top: -70px;
    }

    .main_wrap::before {
        left: -21px;
        top: 41%;
    }

    .main_block {
        padding-top: 0;
        margin-top: 120px;
    }
}

@media screen and (max-width: 992px) {
    .main_content {
        width: 60%;
    }

    .main_img_block {
        position: absolute;
        right: 10px;
        width: 300px;
        top: -70px;
    }

    .main_wrap::before {
        left: -41px;
        top: 31%;
    }
}

@media screen and (max-width: 768px) {
    .main_block {
        flex-direction: column;
        padding-top: 0;
        margin-top: 90px;
    }

    .main_img_block {
        order: 1;
        position: relative;
        top: -40px;
        right: 0;
        margin-left: auto;
    }

    .main_content {
        width: 100%;
        order: 2;
        top: -83px;
        z-index: 2;
    }

    .main_img {
        top: 0;
    }

    .main_undertitle {
        max-width: unset;
        margin-bottom: 25px;
    }

    .main_img--pc {
        display: none;
    }

    .main_img--mob {
        display: block;
    }

    main::before {
        max-height: 1560px;
    }

    .main_wrap::before {
        left: 0;
        top: 14%;
    }
}

@media screen and (max-width: 768px) and (min-width: 577px) and (max-height: 730px) {
    .main_img_block {
        width: 200px;
        right: 30px;
    }
}

@media screen and (max-width: 768px) and (min-width: 577px) and (max-height: 620px) {
    .main_img_block {
        display: none;
    }

    .main_content {
        top: 0px;
    }

    .main_wrap::before {
        left: -50px;
    }
}

@media screen and (max-width: 768px) and (min-width: 577px) and (max-height: 520px) {
    .main_title {
        margin-bottom: 20px;
        font-size: 26px;
    }

    .main_block {
        margin-top: 100px;
    }
}

@media screen and (max-width: 576px) {
    .main_title {
        margin-bottom: 30px;
    }

    .main_btns_wrap {
        flex-direction: column;
    }

    a.button.main_btn,
    button.main_btn {
        width: 100%;
    }

    .main_btns_wrap button {
        margin-right: 0;
        width: 100%;
        margin-bottom: 10px;
    }

    .main_undertitle {
        max-width: 305px;
    }

    .main_btns_wrap {
        max-width: unset;
    }
}

@media screen and (max-width: 576px) and (max-height: 900px) {
    .main_img_block {
        width: 250px;
        right: 20px;
    }

    .main_wrap::before {
        top: 10%;
    }
}

@media screen and (max-width: 576px) and (max-height: 815px) {
    .main_img_block {
        width: 200px;
        right: 40px;
    }

    .main_wrap::before {
        width: 100px;
        height: 100px;
        top: 8%;
    }

    .main_title {
        margin-bottom: 20px;
    }

    .main_undertitle {
        max-width: unset;
    }
}

@media screen and (max-width: 576px) and (max-height: 740px) {
    h1 {
        font-size: 35px;
    }
}

@media screen and (max-width: 576px) and (max-height: 685px) {
    .main_img_block {
        width: 140px;
        right: 70px;
        top: -77px;
    }

    h1 {
        font-size: 28px;
    }

    .main_content {
        top: -74px;
    }

    .main_wrap::before {
        display: none;
    }
}

@media screen and (max-width: 576px) and (max-height: 520px) {
    .main_img_block {
        display: none;
    }

    .main_content {
        top: 0;
    }

    .main_block {
        margin-top: 100px;
    }
}

@media screen and (max-width: 576px) and (max-height: 460px) {
    .main_undertitle {
        font-size: 14px;
    }
}

@media screen and (max-width: 420px) {
    .main_img_block {
        right: 10px;
    }

    .main_wrap::before {
        left: -30px;
        top: 11%;
    }
}

@media screen and (max-width: 420px) and (max-height: 700px) {
    .main_img_block {
        right: 30px;
        top: -77px;
    }
}

@media screen and (max-width: 420px) and (max-height: 610px) {
    .main_undertitle {
        font-size: 16px;
    }

    .main_img_block {
        display: none;
    }

    .main_content {
        top: 0;
    }

    .main_block {
        margin-top: 100px;
    }
}

@media screen and (max-width: 420px) and (max-height: 544px) {
    h1 {
        font-size: 22px;
    }
}