.numbers_wrap {
    background: #411037;
    color: #fff;
    padding: 1px 0;
}

.numbers_title {
    color: #fff;
}

.numbers_item---num {
    font-size: 64px;
    font-weight: 500;
    margin-bottom: 10px;
}

.numbers_item---desc {
    font-size: 14px;
    font-family: 'Gilroy';
    padding-bottom: 17.5px;
    border-bottom: 1px solid #FFFFFF;
}

.numbers_item {
    max-width: 339px;
    width: 100%;
}

.numbers_item:nth-child(1), .numbers_item:nth-child(3) {
    margin-right: 157px;
}

.numbers_item:nth-child(1), .numbers_item:nth-child(2) {
    margin-bottom: 50px;
}

.numbers_content {
    display: flex;
    flex-wrap: wrap;
    max-width: 900px;
}

@media screen and (max-width: 1200px) {
    .numbers_item {
        max-width: 339px;
    }

    .numbers_item:nth-child(1), .numbers_item:nth-child(3) {
        margin-right: 107px;
    }
}

@media screen and (max-width: 992px) {
    .numbers_item {
        max-width: 289px;
    }

    .numbers_item:nth-child(1), .numbers_item:nth-child(3) {
        margin-right: 47px;
    }

    .numbers_content {
        justify-content: space-between;
    }
}

@media screen and (max-width: 768px) {
    .numbers_title {
        margin-bottom: 43px;
    }

    .numbers_item:nth-child(3) {
        margin-bottom: 50px;
    }

    .numbers_item {
        max-width: unset;
    }

    .numbers_item:nth-child(1), .numbers_item:nth-child(3) {
        margin-right: 0;
    }
}