.popup {
    position: fixed;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -10%);
    width: 632px;
    z-index: 99999999999999999;
    display: block;
}

.popup_container {
    width: 632px;
    height: fit-content;
    max-height: calc(100vh - 200px);
    margin: auto;
    background: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 78px 117px;
    overflow: hidden;
    overflow-y: scroll;
}

.popup_title {
    text-align: center;
    font-size: 32px;
    margin-bottom: 30px;
}

.popup_close {
    position: absolute;
    right: 55px;
    top: 45px;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .popup {
        width: 400px;
    }

    .popup_container {
        width: 400px;
        padding: 60px 40px;
    }

    .popup_close {
        right: 35px;
        top: 35px;
    }

    .popup_title {
        font-size: 29px;
    }

    .popup_container {
        max-height: calc(100vh - 50px);
    }

    .popup {
        top: 20%;
    }
}

@media screen and (max-width: 420px) {
    .popup {
        width: 340px;
    }

    .popup_container {
        width: 340px;
        padding: 60px 20px 40px;
    }

    .popup_title {
        font-size: 26px;
    }
}