.join_wrap {
    background-image: url('../../img/join_bcg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    padding: 1px 0;
    margin-top: 200px;
}

.join {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.join_title {
    color: #fff;
    max-width: 358px;
}

.join_text {
    margin-bottom: 35px;
    font-family: 'Gilroy';
    font-weight: 400;
    color: #FFFFFF;
    max-width: 400px;
}

.second_btn.join_btn {
    border-color: #fff;
    color: #fff;
}

.second_btn.join_btn:hover {
    border-color: #fff;
    color: #fff;
    opacity: .5;
}

@media screen and (max-width: 992px) {
    .join {
        margin-top: 340px;
        margin-bottom: 50px;
    }
}

@media screen and (max-width: 768px) {
    .join_wrap {
        margin-top: 130px;
    }

    .join_title {
        margin-bottom: 40px;
    }

    .join {
        margin-top: 400px;
    }
}