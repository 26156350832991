.openMenu {
    position: fixed;
    width: 100vw;
    background-color: #fff;
    height: 100vh;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    transition: 0.5s;
    overflow: scroll;
    z-index: 2;
    padding: 108px 20px 37px;
}
.openMenu ul {
    margin-left: 0;
    width: 100%;
}

.openMenu ul.menu li {
    text-align: left;
}

.menu_btn {
    width: 100%;
    background: #3D67D5;
    margin-bottom: 64px;
}

.menu_inst {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
}

.menu_inst svg {
    width: 44px;
    margin-right: 6px;
}
