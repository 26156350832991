.partners_items {
    display: flex;
    margin-bottom: 50px;
}

.partners_wrap::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    background-image: url('../../img/Partners/partners_bcg.svg');
    background-size: cover;
    width: 1108px;
    height: 1115px;
}

.partners_item {
    background: #FFFFFF;
    box-shadow: 0px 1px 20px rgba(62, 4, 43, 0.05);
    border-radius: 2px;
    width: calc((100% / 5) - 24px);
    margin-right: 24px;
    /* margin-bottom: 50px; */
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 137px;
}

.partners_item:nth-child(5n) {
    margin-right: 0;
}

.partners_img {
    margin: auto;
}

@media screen and (max-width: 1200px) {
    .partners_item {
        width: calc((100% / 4) - 18px);
    }

    .partners_item:nth-child(5) {
        margin-right: 0;
    }

    .partners_item:nth-child(5n) {
        margin-right: 24px;
    }
}

@media screen and (max-width: 992px) {
    .partners_item {
        width: calc((100% / 3) - 12px);
        margin-right: 16px;
        margin-bottom: 16px;
    }

    .partners_items {
        flex-wrap: wrap;
    }

    .partners_item:nth-child(3) {
        margin-right: 0;
    }
}

@media screen and (max-width: 768px) {
    .partners_item {
        width: calc((100% / 2) - 8px);
        margin-right: 16px;
        margin-bottom: 16px;
    }

    .partners_item:nth-child(3) {
        margin-right: 16px;
    }

    .partners_item:nth-child(2n) {
        margin-right: 0;
    }

    .partners_item:nth-child(5n) {
        margin-right: 16px;
    }

    .partners_items {
        flex-wrap: wrap;
    }

    .partners_items {
        margin-bottom: 19px;
    }

    .partners_wrap::before {
        top: 50%;
        right: -20%;
        width: 621px;
        height: 569px;
    }
}