.team_block {
    display: flex;
}

.team_item {
    margin-right: 56px;
    max-width: 316px;
    width: 100%;
}

.team_item:last-of-type {
    margin-right: 0;
}

.team_photo {
    margin-bottom: 20px;
}

.team_name {
    font-weight: 700;
    font-size: 22px;
    line-height: 121%;
    color: #411037;
    margin-bottom: 15px;
}

.team_position {
    font-family: 'Gilroy';
    font-weight: 400;
    font-size: 18px;
    line-height: 121%;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(53, 49, 52, 0.5);
}

.team_block--mob {
    display: none;
}

.thumbs {
    display: none;
}

@media screen and (max-width: 1200px) {
    .team_item {
        margin-right: 30px;
    }

    .team_position {
        font-size: 16px;
    }
}

@media screen and (max-width: 992px) {
    .team_item {
        max-width: unset;
        width: calc((100% / 2) - 15px);
        margin-bottom: 30px;
    }

    .team_item:nth-child(2n+1) {
        margin-right: 30px;
    }

    .team_item:nth-child(2n) {
        margin-right: 0;
    }

    .team_block {
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 768px) {
    .team_block--pc {
        display: none;
    }

    .team_block--mob {
        display: block;
    }

    .team_item {
        width: 100%;
        margin-bottom: 40px;
        margin-right: 0;
        text-align: left;
    }

    .carousel .slide {
        min-width: 95%;
    }

    .team_item {
        width: 96%;
    }

    .slide {
        opacity: .5;
    }

    .slide.selected {
        opacity: 1;
    }

    .slider {
        pointer-events: none;
    }

    .carousel .control-dots .dot {
        background: #FFB2E6;
        box-shadow: unset;
    }

    .carousel .control-next.control-arrow:before {
        border-left: 15px solid #FFB2E6;
    }

    .carousel .control-prev.control-arrow:before {
        border-right: 15px solid #FFB2E6;
    }

    .carousel.carousel-slider .control-arrow:hover {
        background: transparent;
    }

    .carousel .control-arrow, .carousel.carousel-slider .control-arrow {
        opacity: 1;
    }

    .carousel .control-dots {
        margin: 0;
    }

    .control-arrow.control-next {
        background: transparent!important;
    }

    .control-arrow:focus {
        filter: none;
    }

    .team_photo {
        margin-bottom: 10px;
    }

    .team_name {
        margin-bottom: 5px;
    }

    .team_position {
        padding-bottom: 10px;
    }
}