.how_it_works {
    margin-top: 50px;
}

.how_it_works_block {
    margin-bottom: 60px;
    display: flex;
}

.how_it_works_item {
    display: flex;
    align-items: center;
    max-width: 236px;
    margin-right: 160px;
    font-family: 'Gilroy';
}

.how_it_works_item:last-child {
    max-width: 306px;
    margin-right: 0;
}

.how_it_works_img {
    margin-right: 30px;
    max-height: 55px;
    object-fit: contain;
}

.how_it_works_title {
    text-align: left;
}

.how_it_works_desc {
    max-width: 765px;
    font-family: 'Gilroy';
    margin-bottom: 60px;
}

.audio_title {
    margin-bottom: 40px;
}

.audio_block--content {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    color: #fff;
    font-weight: 500;
}

.audio_items {
    display: flex;
    width: 100%;
}

.audio_item {
    max-width: 426px;
    max-height: 224px;
    height: 224px;
    width: 426px;
    border-radius: 20px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    padding: 20px;
    background-position: center;
}

.audio_item:first-child {
    margin-right: 30px;
}

.audio_item--content {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.audio_play {
    width: 50px;
    height: 50px;
    margin-right: 11px;
    object-fit: contain;
    cursor: pointer;
}

.audio_listen {
    font-size: 14px;
    margin-right: 7px;
}

.audio_micro {
    max-width: 39px;
    object-fit: contain;
    margin: 0 auto 10px;
}

.audio_waves {
    max-height: 19px;
    object-fit: contain;
    margin-bottom: 9px;
}

.audio_desc {
    font-size: 18px;
}

@media screen and (max-width: 1400px) {
    .audio_item {
        width: 45%;
        padding: 15px;
    }

    .audio_waves {
        max-height: unset;
        object-fit: cover;
        width: 162px;
        margin-bottom: 1px;
    }

    .how_it_works_item {
        margin-right: 90px;
    }
}

@media screen and (max-width: 1200px) {
    .audio_waves {
        object-fit: none;
        width: 100px;
    }

    .how_it_works_item {
        margin-right: 30px;
    }
}

@media screen and (max-width: 992px) {
    .audio_block--content {
        flex-wrap: wrap;
    }

    .audio_play {
        width: 47px;
        height: 47px;
    }

    .audio_items {
        justify-content: space-between;
        margin-bottom: 35px;
    }

    .button.audio_btn {
        margin-right: auto;
    }

    .audio_item:first-child {
        margin-right: 35px;
    }

    .audio_item {
        width: 50%;
    }

    .how_it_works_item {
        margin-bottom: 43px;
        max-width: 246px;
    }

    .how_it_works_item:last-child {
        margin-bottom: 0;
    }

    .how_it_works_block {
        flex-direction: column;
    }

    .how_it_works_item:first-of-type .how_it_works_img{
        margin-left: 7px;
        margin-right: 42px;
    }
}

@media screen and (max-width: 768px) {
    .audio_item--content {
        flex-wrap: wrap;
    }

    .audio_play {
        margin-bottom: 10px;
    }

    .audio_waves {
        width: 100%;
    }

    .audio_item {
        padding: 10px;
    }
}

@media screen and (max-width: 576px) {
    .audio_waves {
        width: 166px;
        margin-bottom: 5px;
    }

    .audio_item--content {
        flex-wrap: nowrap;
    }

    .audio_items {
        flex-direction: column;
    }

    .audio_item {
        margin-right: 0;
        width: 100%;
        max-width: unset;
        height: 204px;
    }

    .audio_item:first-child {
        margin-bottom: 20px;
        margin-right: 0;
    }

    .how_it_works_img {
        max-height: 36px;
        margin-right: 17px;
    }

    .how_it_works_item:first-of-type .how_it_works_img {
        margin-right: 24px;
    }

    .how_it_works_item {
        max-width: unset;
        margin-bottom: 46px;
    }

    .how_it_works_desc {
        margin-bottom: 50px;
    }

    .audio_item {
        padding: 20px;
    }
}

@media screen and (max-width: 392px) {
    .audio_waves {
        width: 120px;
        margin-bottom: 5px;
    }
}