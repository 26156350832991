.guides_wrap::before {
    content: '';
    position: absolute;
    top: -100%;
    left: -10%;
    background-image: url('../../img/pinkblue_bcg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    width: 100vw;
    height: 2080px;
}

.guides {
    display: flex;
}

.guides_title {
    width: 40%;
}

.guides_content {
    width: 60%;
    font-family: 'Gilroy';
    font-size: 20px;
    line-height: 170%;
    padding-left: 40px;
}

.guides_desc {
    margin-bottom: 70px;
}

.guides_desc:first-child {
    margin-bottom: 10px;
}

@media screen and (max-width: 1400px) {
    .guides_title {
        width: 35%;
    }

    .guides_content {
        width: 65%;
    }
}

@media screen and (max-width: 1200px) {
    .guides {
        flex-direction: column;
    }

    .guides_title {
        width: 100%;
    }

    .guides_content {
        width: 100%;
    }

    .guides_content {
        padding-left: 0;
    }

    .guides_wrap::before {
        width: 1300px;
    }
}

@media screen and (max-width: 768px) {
    .guides_desc {
        margin-bottom: 35px;
    }

    .guides_btn.button {
        margin-right: auto;
    }

    .guides_wrap::before {
        top: -30%;
        left: -60%;
    }
}

@media screen and (max-width: 420px) {
    .guides_wrap::before {
        left: -130%;
    }
}