.countries_title {
    width: 69%;
}

.countries {
    display: flex;
    justify-content: space-between;
}

.countries::before {
    content: '';
    background-image: url('../../img/countries/map.svg');
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    left: -20px;
    top: -270px;
    width: 803px;
    height: 593px;
}

.countries_item {
    padding: 30px 15px;
    background: #FFFFFF;
    box-shadow: 0px 1.20478px 24.0956px rgba(62, 4, 43, 0.05);
    border-radius: 2.4px;
    margin-right: 40px;
    margin-bottom: 40px;
}

.countries_item:nth-child(3) {
    margin-right: 0;
}

.countries_item:nth-child(4), .countries_item:nth-child(5), .countries_item:nth-child(6) {
    margin-bottom: 0;
}

.countries_items {
    display: flex;
    flex-wrap: wrap;
}

.countries_flag {
    margin-bottom: 25px;
}

.countries_country {
    font-family: 'Gilroy';
    font-weight: 600;
    font-size: 24px;
    color: #3A2471;
    margin-bottom: 6px;
}

.countries_call {
    margin-top: 17px;
    display: flex;
    align-items: center;
    color: #39226D;
}

.countries_call--img {
    width: 50px;
    height: 50px;
    margin-left: 16px;
}

@media screen and (max-width: 1400px) {
    .countries_item {
        padding: 25px 10px;
        margin-right: 20px;
        margin-bottom: 20px;
    }

    .countries_call--img {
        width: 40px;
        height: 40px;
        margin-left: 12px;
    }
}

@media screen and (max-width: 1200px) {
    .countries_title {
        width: 1010px;
    }

    .countries_item:nth-child(3) {
        margin-right: 20px;
    }
    
    .countries_item:nth-child(4) {
        margin-bottom: 20px;
    }

    .countries_country {
        font-size: 21px;
    }
}

@media screen and (max-width: 992px) {
    .countries {
        flex-direction: column;
    }

    .countries_title {
        width: 100%;
    }

    .countries::before {
        top: -200px;
        width: 633px;
    }

    .countries_item:nth-child(3) {
        margin-right: 0px;
    }

    .countries_item:nth-child(4) {
        margin-bottom: 0px;
    }
}

@media screen and (max-width: 768px) {
    .countries_item {
        width: calc((100% / 3) - 7px);
    }

    .countries_country {
        font-size: 22px;
    }

    .countries_phone {
        font-size: 18px;
    }

    .countries_item {
        padding: 20px 6px;
        margin-right: 10px;
        margin-bottom: 10px;
    }

    .countries_call {
        font-size: 14px;
    }

    .countries::before {
        top: -130px;
        width: 493px;
    }

    .countries_item:nth-child(6) {
        margin-right: 0;
    }
}

@media screen and (max-width: 576px) {
    .countries_item {
        width: calc((100% / 2) - 14px);
        margin-right: 14px;
        margin-bottom: 14px;
    }

    .countries_item:nth-child(3) {
        margin-right: 14px;
    }

    .countries_item:nth-child(4) {
        margin-bottom: 14px;
    }

    .countries::before {
        top: -100px;
        width: 353px;
        left: 0;
    }
}