ul.menu{
    display: flex;
    justify-content: flex-end;
    list-style: none;
    margin: 0;
    padding: 0;
}
ul.menu li{
    text-align: center;
}

.menu_pc {
    transition: .4s;
    margin: 0 24px;
    position: relative;
    transition: 1s ease-in;
    z-index: 2;
}

ul.menu li a {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 16px;
    color: #353134;
    text-decoration: none;
    width: fit-content;
    font-family: 'Gilroy', sans-serif;
}

.menu_pc::before {
    content: '';
    position: absolute;
    bottom: -3px;
    left: 5%;
    width: 90%;
    height: 1px;
    background-color: #353134;
    opacity: 0;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s;
    z-index: -1;
}

.menu_pc:hover::before {
    transform: scaleX(1);
    opacity: 1;
}

.mobile-menu { 
    display: none; 
    justify-content: space-between; 
} 

.menu_toggle_wrapper {
    display: block;
    text-align: right;
    width: 44px;
    height: 28px;
    cursor: pointer;
    margin-right: 15px;
}

.menu_toggle_wrapper.active .menu_toggle {
    height: 0;
}

.menu_toggle_wrapper.active .menu_toggle::before {
    top: 0;
    transform: rotate(45deg);
    background: #3D67D5B2;
    width: 29px!important;
    height: 1px;
}

.menu_toggle_wrapper.active .menu_toggle::after {
    top: 0;
    transform: rotate(-45deg);
    width: 29px!important;
    background: #3D67D5B2;
    height: 1px;
}

.menu_toggle_wrapper.active.menu_toggle_wrapper:hover .menu_toggle {
    width: 0;
}

.menu_toggle {
    position: relative;
    display: inline-block;
    transition: .3s all ease;
    width: 27px;
    height: 3px;
    background-color: #411037;
    transform-origin: center;
    border-radius: 3px;
}

.menu_toggle::before,
.menu_toggle::after {
    height: 3px;
    background-color: #411037;
    border-radius: 3px;
}

.menu_toggle::before {
    width: 32px;
    content: "";
    position: absolute;
    right: 0;
    top: -7px;
    transition: .3s all ease;
}

.menu_toggle::after {
    content: "";
    position: absolute;
    right: 0;
    width: 32px;
    top: 7px;
    transition-duration: .3s;
    transform-origin: center;
}

.menu_toggle_wrapper:hover .menu_toggle {
    width: 42px;
}

.menu_toggle_wrapper:hover .menu_toggle::after {
    width: 42px;
}

.menu_toggle_wrapper:hover .menu_toggle::before {
    width: 42px;
}

@media screen and (max-width: 1200px) {
    .menu_pc { 
        display: none; 
    } 
 
    .mobile-menu { 
        display: flex; 
        align-items: center;
        margin-left: auto; 
        width: 100%; 
    } 
 
    .mobile-menu_img-block { 
        width: 58px;
        height: 31px;
        margin-left: auto;
        margin-top: auto;
        margin-bottom: auto;
        z-index: 5;
    } 
 
    ul.menu { 
        width: 100%; 
        left: 50%;
        top: 0px;
        padding-top: 26px;
    } 

    .header_wrap--scroll ul.menu {
        padding-top: 0
    }

    ul.menu li a {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.3);
        width: 100%;
        border-radius: 0;
        justify-content: flex-start;
        font-size: 18px;
    }

    .header_logo {
        display: none;
    }

    .logo-mob {
        max-width: 139px;
        width: 100%;
        z-index: 5;
    }

    .logo-mob.active {
        display: none;
    }

    .header_wrap--scroll .header_container {
        padding-top: 0
    }

    .header_container {
        padding-top: 44px;
        margin-top: 0;
    }

    ul.menu li.menu_pc--without_before a {
        padding-top: 0;
    }
}

@media screen and (max-width: 768px) {
    .header_wrap.header_wrap--scroll {
        padding: 5px 0;
    }
}