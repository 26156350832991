.footer_wrap {
    position: relative;
    background: #411037;
    padding: 1px 0;
    color: #fff;
    font-size: 16px;
    font-family: 'Gilroy';
    font-weight: 600;
}

.container.footer {
    margin: 80px auto;
}

.footer_img {
    max-width: 250px;
    object-fit: contain;
    width: 100%;
}

.footer {
    display: flex;
    justify-content: space-between;
}

.footer_content {
    display: flex;
}

.footer_logo {
    max-width: 186px;
}

.footer_data {
    margin-right: 114px;
}

.footer_data--item {
    margin-bottom: 30px;
    display: block;
}

.footer_data--item:last-child {
    margin-bottom: 0;
}

.footer_phone {
    transition: .4s;
}

.footer_phone:hover {
    opacity: .5;
}

.footer_menu--item {
    margin-bottom: 30px;
    transition: .4s;
}

.footer_menu--item:hover {
    opacity: .5;
}

.footer_menu--item:last-child {
    margin-bottom: 0;
}

.footer_tg--mob {
    display: none;
}

.footer_lb {
    display: none;
}

.footer_img_block--mob {
    display: none;
}

.footer_data {
    max-width: 270px;
}

@media screen and (max-width: 1400px) {
}

@media screen and (max-width: 992px) {
    .footer_data {
        margin-right: 44px;
        max-width: 197px;
    }
}

@media screen and (max-width: 768px) {
    .footer_content {
        flex-direction: column;
    }

    .footer_tg {
        display: none;
    }

    .footer_img {
        max-width: 150px;
    }

    .footer_data--item {
        margin-bottom: 22px;
    }

    .footer_menu--item {
        margin-bottom: 22px;
    }
    
    .footer_lb {
        display: block;
    }

    .footer_content {
        order: 2;
        text-align: right;
    }

    .footer_img {
        display: none;
    }

    .footer_img_block--mob {
        display: flex;
        flex-direction: column;
        order: 1;
    }

    .footer_tg--mob {
        display: block;
    }

    .footer_img--mob {
        margin: auto;
    }

    .footer_lb {
        position: absolute;
        bottom: -50px;
        left: 20px;
    }

    .footer_data {
        margin-right: 0;
    }
}