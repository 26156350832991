.translateTo_content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.translateTo_img {
    max-width: 426px;
    width: 100%;
    margin-right: 40px;
}

.translateTo_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 19px;
    margin-bottom: 25px;
    border-bottom: 1px solid rgba(53, 49, 52, 0.5);
}

.translateTo_item--content {
    display: flex;
    align-items: flex-start;
}

.translateTo_flag {
    max-width: 50px;
    object-fit: contain;
    margin-right: 10px;
}

.translateTo_country {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 5px;
}

.translateTo_phone {
    font-family: 'Gilroy';
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #000000;
}

.translateTo_call {
    max-width: 132px;
    padding: 14px 10px;
}

.translateTo_items {
    max-width: 540px;
    width: 100%;
}

@media screen and (max-width: 1200px) {
    .translateTo_img {
        max-width: 306px;
    }
}

@media screen and (max-width: 992px) {
    .translateTo_img {
        display: none;
    }

    .translateTo_items {
        max-width: unset;
    }
}

@media screen and (max-width: 768px) {
    .translateTo_flag {
        max-width: 37px;
    }

    .translateTo_phone {
        font-size: 18px;
    }

    .translateTo_item {
        padding-bottom: 27px;
        margin-bottom: 33px;
    }
}

@media screen and (max-width: 400px) {
    .translateTo_item {
        flex-direction: column;
        align-items: flex-start;
    }

    .translateTo_item--content {
        margin-bottom: 10px;
    }

    a.button.second_btn.translateTo_call {
        max-width: unset;
        padding: 11px 10px;
    }
}