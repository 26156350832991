div{position: relative;}

.container {
    max-width: 1400px;
    margin: 200px auto;
    padding: 0 100px;
}

.container--full {
    margin: 200px auto;
}

h1{
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 1.2;
    color: #411037;
    margin:0;
    z-index: 5;
}
h2{
    text-align: left;
    font-weight: 600;
    font-size: 48px;
    line-height: 1.2;
    color: #411037;
    margin:0;
    margin-bottom: 80px;
}
h3 {
    text-align: center;
    font-weight: 700;
    font-size: 30px;
    color: #F3B4E3;
    text-transform: uppercase;
    margin-right: 30px;
}
h4 {
    font-weight: 400;
    font-size: 26px;
    color: rgba(57, 34, 109, .8);
}
a.button, .button{
    display: block;
    align-items: center;
    justify-content: center;
    width: 245px;
    padding: 19px 10px;
    font-weight: 700;
    text-decoration: none;
    text-align: center;
    font-size: 14px;
    line-height: 133.02%;
    color: #FFFFFF;
    background: linear-gradient(125.45deg, #336AD3 -1.09%, #EC04B1 108.35%);
    background: -webkit-linear-gradient(125.45deg, #336AD3 -1.09%, #EC04B1 108.35%);
    transition: .4s;
    /* height: 100%; */
    /* border: 2px solid transparent; */
}

a.button:hover, .button:hover {
    background: linear-gradient(125.45deg, rgba(51, 106, 211, 0.85) -1.09%, rgba(236, 4, 177, 0.85) 108.35%);
    background: -webkit-linear-gradient(125.45deg, rgba(51, 106, 211, 0.85) -1.09%, rgba(236, 4, 177, 0.85) 108.35%);
}

a.button:pressed, .button:pressed {
    background: linear-gradient(125.45deg, #336AD3 -1.09%, #EC04B1 108.35%);
    background: -webkit-linear-gradient(125.45deg, #336AD3 -1.09%, #EC04B1 108.35%);
    box-shadow: inset 4px 4px 25px rgba(65, 16, 55, 0.3);
}

a.button.second_btn, button.second_btn {
    display: block;
    align-items: center;
    justify-content: center;
    width: 245px;
    padding: 18px 10px;
    font-weight: 700;
    text-decoration: none;
    text-align: center;
    font-size: 14px;
    line-height: 133.02%;
    color: #3D67D5;
    background: transparent;
    border: 2px solid #3D67D5;
    transition: .4s;
    /* height: 100%; */
}

a.button.second_btn:hover, button.second_btn:hover {
    border: 2px solid rgba(61, 103, 213, 0.6);
    color: rgba(61, 103, 213, 0.6);
}

a.button.second_btn:pressed, button.second_btn:pressed {
    border: 3px solid #3D67D5;
    color: #3D67D5;
}

a, button {
    border-radius: 100px;
}

iframe {
    border: none;
}

main {
    overflow-x: hidden;
}

@media screen and (max-width: 1400px) {
    .container{
        max-width: 1100px;
        padding: 0 50px;
    }

    h1 {
        font-size: 44px;
    }
}

@media screen and (max-width: 1200px) {
    .container{
        max-width: 920px;
    }

    h1 {
        font-size: 35px;
    }
}

@media screen and (max-width: 992px) {
    .container{
        max-width: 680px;
        padding: 0 20px;
    }
}

@media screen and (max-width: 768px) {
    .container{
        max-width: 500px;
        margin: 130px auto;
    }

    h1 {
        font-size: 40px;
    }
}

@media screen and (max-width: 576px) {
    .container{
        max-width: 100%;
    }

    h1 {
        font-size: 46px;
    }

    h2 {
        font-size: 34px;
        margin-bottom: 40px;
    }

    h4 {
        font-size: 22px;
    }
}

@media screen and (max-width: 554px) {
    h1 {
        font-size: 45px;
    }
}

@media screen and (max-width: 420px) {
    h1 {
        font-size: 10vw;
    }
}

.logo_item{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
}

@media screen and (max-width: 640px) {
    .logo_item {
        width: calc(90% / 3);
    }
}

@media screen and (max-width: 440px) {
    .logo_item {
        width: calc(90% / 2);
    }
}