header {
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: space-between;
    z-index: 555;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    height: 58px;
    margin: 75px auto 0;
}

.header_container {
    max-width: 1400px;
    padding: 0 100px;
}

.header_wrap.header_wrap--scroll {
    background: rgba(255, 255, 255, 0.9);
    display: block;
    width: 100vw;
    height: fit-content;
    position: fixed;
    top: 0;
    padding: 20px 0;
    z-index: 10000000;
}

.header_wrap.header_wrap--scroll .header_container {
    position: relative;
    left: unset;
    transform: unset;
    margin: 0 auto;
}

.header_wrap.header_wrap--scroll ul.menu {
    position: relative;
    width: fit-content;
    left: unset;
    transform: unset;
    margin: 0;
}

.header_logo {
    max-width: 128px;
    width: 100%;
    margin-right: 91px;
}

@media screen and (max-width: 1400px) {
    .header_container {
        max-width: 1100px;
        padding: 0 50px;
    }

    header {
        margin: 45px auto 0;
    }
}

@media screen and (max-width: 1200px) {
    .header_container {
        max-width: 920px;
    }

    .header_wrap.header_wrap--scroll ul.menu {
        width: 100%;
    }
}

@media screen and (max-width: 992px) {
    .header_container {
        max-width: 680px;
        padding: 0 20px;
    }
}

@media screen and (max-width: 768px) {
    .header_container {
        max-width: 500px;
    }
}

@media screen and (max-width: 640px) {
    header {
        margin: 10px auto 0;
    }
}

@media screen and (max-width: 576px) {
    .header_container {
        max-width: 100%;
    }
}