.popup_form {
    width: 100%;
}

.popup_input {
    background: rgba(255, 255, 255, 0.3);
    border: 1px solid #3D67D5;
    border-radius: 64px;
    font-family: 'Gilroy';
    font-weight: 400;
    font-size: 12px;
    color: #3D67D5;
    width: 100%;
    display: block;
}

.popup_inputs_block {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
}

.popup_inputs_block label {
    margin-bottom: 15px;
}

.popup_inputs_block label:last-child {
    margin-bottom: 30px;
}

.popup_inputs_block label:last-child .popup_input {
    border-radius: 8px;
}

.popup_btn {
    border-radius: 100px;
    width: 100%;
}

.popup_error {
    font-family: 'Gilroy';
    font-weight: 400;
    font-size: 12px;
    line-height: 121%;
    color: #EB5757;
    margin-left: 20px;
    margin-bottom: 3px;
}

.form_message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.form_message--img {
    max-width: 100px;
    object-fit: contain;
    width: 100%;
    margin-bottom: 20px;
}

.popup_input--textarea {
    border-radius: 8px;
}