.support {
    display: flex;
    justify-content: space-between;
}

.support_text {
    font-family: 'Gilroy';
    font-weight: 300;
    font-size: 20px;
}

.support_title {
    width: 40%;
}

.support_content {
    width: 60%;
}

.support_text {
    margin-bottom: 10px;
}

.support_text:last-of-type {
    margin-bottom: 50px;
}

@media screen and (max-width: 1400px) {
    .support_title {
        width: 35%;
    }
    
    .support_content {
        width: 65%;
    }
}

@media screen and (max-width: 1200px) {
    .support {
        flex-direction: column;
    }

    .support_title {
        width: 100%;
    }
    
    .support_content {
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .support_title {
        margin-bottom: 40px;
    }

    .support_text:last-of-type {
        margin-bottom: 35px;
    }

    .button.support_btn {
        margin-right: auto;
    }
}