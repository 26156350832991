.free_title {
    margin-bottom: 60px;
    text-align: center;
}

.free_text {
    font-family: 'Gilroy';
    text-align: center;
    max-width: 700px;
    margin: 0 auto;
}

@media screen and (max-width: 576px) {
    .free_title {
        text-align: left;
        margin-bottom: 40px;
    }

    .free_text {
        text-align: left;
    }
}