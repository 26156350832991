@font-face {
  font-family: "e-Ukraine"; 
  src: url("./fonts/e-Ukraine-Light.otf") format("truetype"); 
  font-style: normal; 
  font-weight: 300; 
} 

@font-face {
  font-family: "e-Ukraine"; 
  src: url("./fonts/e-Ukraine-Light.otf") format("truetype"); 
  font-style: normal; 
  font-weight: 400; 
} 

@font-face {
  font-family: "e-Ukraine"; 
  src: url("./fonts/e-Ukraine-Medium.otf") format("truetype"); 
  font-style: normal; 
  font-weight: 500; 
} 

@font-face {
  font-family: "e-Ukraine"; 
  src: url("./fonts/e-Ukraine-Bold.otf") format("truetype"); 
  font-style: normal; 
  font-weight: 700; 
} 

@font-face {
  font-family: "Gilroy"; 
  src: url("./fonts/Gilroy-Light.otf") format("truetype"); 
  font-style: normal; 
  font-weight: 300; 
} 

* {
  margin: 0;
  padding: 0;
  font-family: inherit;
  word-break: break-word;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  outline: none;
}

body {
  position: relative;
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  min-height: 100vh;
  background-color: #fff;
  color: #353134;
  font-family: 'e-Ukraine-Regular', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.4;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
}

img {
  max-width: 100%;
  border-style: none;
  display: block;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

svg {
  display: block;
  width: 100%;
}

ul li,
ol li {
  list-style-type: none;
}

label {
  cursor: pointer;
}

button {
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}

iframe {
  border: none;
  outline: none;
}

input,
textarea {
  padding: 14px 20px;
  background: #E5E5E5;
  font-size: 15px;
  line-height: 20px;
  outline: none;
  border-radius: 5px;
  border: none;
  transition: all 0.2s ease-in-out;
}

textarea {
  min-height: 104px;
  padding: 10px 20px;
  resize: none;
}

input.invalid,
textarea.invalid {
  border-color: #ff0000;
}

input:focus,
textarea:focus {
  outline: none;
  border-color: #31beff;
}

input::placeholder,
textarea::placeholder {
  color: rgba(51, 51, 51, 0.5);
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
}

input.fill {
  border-color: #008000;
}

input[type=text]::-ms-clear  {  display: none; width : 0; height: 0; }
input[type=text]::-ms-reveal  {  display: none; width : 0; height: 0; }
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration  { display: none; }
input[type=text]::-ms-clear  {  display: none; width : 0; height: 0; }
input[type=text]::-ms-reveal  {  display: none; width : 0; height: 0; }
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration  { display: none; }

@media screen and (max-width: 768px) {
  body {
    font-size: 18px;
  }
}